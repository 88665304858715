// Libraries
import Konva from 'konva';

// Assets
import {
  rockImg,
  rocksImg,
  treeImg,
  treesImg,
  cornerHillAImg,
  doubleHillImg,
  hillImg,
  houseAImg,
  cornerWallImg,
  pondAImg,
  roadAImg,
  towerImg,
} from './terrain';

// Constants
import { TERRAIN_TYPES } from './constants';
const {
  ROCK,
  ROCKS,
  HILL,
  DOUBLE_HILL,
  TREE,
  TREES,
  HOUSE_A,
  CORNER_WALL,
  CORNER_HILL_A,
  POND_A,
  ROAD_A,
  TOWER,
} = TERRAIN_TYPES;

const terrainMap = {
  [ROCK]: rockImg,
  [ROCKS]: rocksImg,
  [HILL]: hillImg,
  [DOUBLE_HILL]: doubleHillImg,
  [TREE]: treeImg,
  [TREES]: treesImg,
  [HOUSE_A]: houseAImg,
  [CORNER_HILL_A]: cornerHillAImg,
  [CORNER_WALL]: cornerWallImg,
  [POND_A]: pondAImg,
  [ROAD_A]: roadAImg,
  [TOWER]: towerImg,
};

const generateTerrainFeature = (layer, type = ROCK) => {
  if (typeof window === 'undefined') return;

  const img = terrainMap[type];

  const feature = new Konva.Rect({
    x: 0,
    y: 0,
    width: img.width,
    height: img.height,
    fillPatternImage: img,
    name: 'rect',
    draggable: true,
    scale: {
      x: 0.25,
      y: 0.25,
    },
  });

  layer.add(feature);
};

export default generateTerrainFeature;
