// Libraries
import React, { useEffect, useState } from 'react';

const useIsMobile = () => {
  if (typeof window === 'undefined') return { isMobile: false };

  const [isMobile, setIsMobile] = useState(
    document.documentElement.clientWidth < 1045
  );

  const handleResize = () => {
    setIsMobile(document.documentElement.clientWidth < 1045);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile };
};

export default useIsMobile;
