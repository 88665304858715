// Exports all terrain
// Syntax -> fileNameImg

import rockPNG from '../../public/terrain/rock.png';
import rocksPNG from '../../public/terrain/rocks.png';
import treePNG from '../../public/terrain/tree.png';
import treesPNG from '../../public/terrain/trees.png';
import cornerHillAPNG from '../../public/terrain/corner_hill_a.png';
import doubleHillPNG from '../../public/terrain/double_hill.png';
import hillPNG from '../../public/terrain/hill.png';
import houseAPNG from '../../public/terrain/house_a.png';
import cornerWallPNG from '../../public/terrain/wall_corner_a.png';
import pondAPNG from '../../public/terrain/pond_a.png';
import roadAPNG from '../../public/terrain/road_a.png';
import towerPNG from '../../public/terrain/tower.png';
import mapPNG from '../../public/map.png';
import framePNG from '../../public/frame.png';

if (typeof window === 'undefined') {
  global.Image = class {
    constructor(width, height) {
      this.width = width;
      this.height = height;
    }
  };
}

const rockImg = new Image();
rockImg.src = rockPNG;

const rocksImg = new Image();
rocksImg.src = rocksPNG;

const treeImg = new Image();
treeImg.src = treePNG;

const treesImg = new Image();
treesImg.src = treesPNG;

const cornerHillAImg = new Image();
cornerHillAImg.src = cornerHillAPNG;

const doubleHillImg = new Image();
doubleHillImg.src = doubleHillPNG;

const hillImg = new Image();
hillImg.src = hillPNG;

const houseAImg = new Image();
houseAImg.src = houseAPNG;

const cornerWallImg = new Image();
cornerWallImg.src = cornerWallPNG;

const pondAImg = new Image();
pondAImg.src = pondAPNG;

const roadAImg = new Image();
roadAImg.src = roadAPNG;

const towerImg = new Image();
towerImg.src = towerPNG;

// Battle Map
const mapImg = new Image();
mapImg.src = mapPNG;

const frameImg = new Image();
frameImg.src = framePNG;

export {
  cornerHillAImg,
  cornerWallImg,
  doubleHillImg,
  hillImg,
  houseAImg,
  mapImg,
  rockImg,
  rocksImg,
  treeImg,
  treesImg,
  pondAImg,
  roadAImg,
  towerImg,
  frameImg,
};
