// extracted by mini-css-extract-plugin
export var battleMap = "battle-map-module--battleMap--Y9U8K";
export var battleMapApplet = "battle-map-module--battleMapApplet--NFznS";
export var battleMapContainer = "battle-map-module--battleMapContainer--e4ifC";
export var instructions = "battle-map-module--instructions--EnJX8";
export var lockButton = "battle-map-module--lockButton--RPfNk";
export var menuItemList = "battle-map-module--menuItemList--Br0-l";
export var menuItemListItem = "battle-map-module--menuItemListItem--wb9lR";
export var saveButton = "battle-map-module--saveButton--Cdspv";
export var saveButtonContainer = "battle-map-module--saveButtonContainer--9QYAt";
export var terrainMenu = "battle-map-module--terrainMenu--MAsZH";