// Libraries
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
// Styles
import * as styles from './hero.module.css';
// Assets
import heroImage from '../../public/hero.webp';
// Utils
import * as Constants from '../utils/constants';

class Hero extends React.Component {
  render() {
    const { image, useImageTag } = this.props;

    return (
      <div className={styles.hero}>
        {image && !useImageTag ? (
          <GatsbyImage
            className={styles.image}
            alt={this.props.title}
            image={image}
          />
        ) : (
          <img className={styles.image} alt="Hero" src={image || heroImage} />
        )}
        <div className={styles.details}>
          <h1 className={styles.title}>{this.props.title}</h1>
          <p className={styles.content}>{this.props.content}</p>
        </div>
      </div>
    );
  }
}

Hero.defaultProps = {
  title: Constants.SITE_NAME,
  content: 'Musings on fantastic worlds & distant lands...',
};

export default Hero;
