// Libraries
import React from 'react';

// Assets
import {
  rockImg,
  rocksImg,
  treeImg,
  treesImg,
  doubleHillImg,
  cornerHillAImg,
  hillImg,
  houseAImg,
  cornerWallImg,
  pondAImg,
  roadAImg,
  towerImg,
} from '../utils/terrain';

// Styles
import * as styles from '../components/battle-map.module.css';

// Constants
import { TERRAIN_TYPES } from '../utils/constants';
import generateTerrainFeature from '../utils/terrainFeature';
const {
  ROCK,
  ROCKS,
  HILL,
  DOUBLE_HILL,
  TREE,
  TREES,
  HOUSE_A,
  CORNER_WALL,
  CORNER_HILL_A,
  POND_A,
  ROAD_A,
  TOWER,
} = TERRAIN_TYPES;

const terrain = [
  { img: rockImg, name: ROCK },
  { img: rocksImg, name: ROCKS },
  { img: treeImg, name: TREE },
  { img: cornerHillAImg, name: CORNER_HILL_A },
  { img: treesImg, name: TREES },
  { img: doubleHillImg, name: DOUBLE_HILL },
  { img: hillImg, name: HILL },
  { img: houseAImg, name: HOUSE_A },
  { img: cornerWallImg, name: CORNER_WALL },
  { img: pondAImg, name: POND_A },
  { img: roadAImg, name: ROAD_A },
  { img: towerImg, name: TOWER },
];

const TerrainMenu = ({ layer }) => {
  const renderTerrainMenuItems = () => {
    return terrain.map(({ img, name }, idx) => {
      return (
        <li
          className={styles.menuItemListItem}
          key={idx}
          onClick={() => generateTerrainFeature(layer, name)}
        >
          <img src={img.src} alt={name} width={100} height={100} />
          <p>{name.charAt(0).toUpperCase() + name.slice(1)}</p>
        </li>
      );
    });
  };

  return <ul className={styles.menuItemList}>{renderTerrainMenuItems()}</ul>;
};

export default TerrainMenu;
