// Libraries
import React from 'react';

// Styles
import * as styles from './loader.module.css';

const Loader = ({ children, isLoading, text, hasError }) => {
  if (isLoading || hasError) {
    return (
      <div className={styles.loaderContainer}>
        <div className={hasError ? styles.error : styles.loader}></div>
        {text && <h4>{text}</h4>}
      </div>
    );
  }

  return children;
};

export default Loader;
