// Libraries
import React from 'react';

// Components
import BattleMap from '../../components/battle-map';
import Hero from '../../components/hero';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

// Styles
import * as styles from '../../components/battle-map.module.css';

const Instructions = () => {
  return (
    <details className={styles.instructions}>
      <summary>Instructions</summary>
      <ul>
        <li>To add a terrain features simply click one from the menu</li>
        <li>
          You can multi-select items by command/ctrl clicking each item, or
          dragging the mouse across the features you want to select
        </li>
        <li>
          Items can be copy/pasted. First select the item/s you want to copy,
          press command + c, followed by command + v
        </li>
        <li>
          Items can be resized, rotated, etc. Use the resize and rotation
          handles after selecting the terrain feature you want to work with.
        </li>
        <li>
          To delete an item/s make your selection then hit the backspace key.
        </li>
        <li>
          To adjust z-index, select the item/s and press shift + "+" to move the
          selection up 1 or shift + "_" to move the selection down 1.
        </li>
      </ul>
    </details>
  );
};

const BattleMapper = (props) => {
  return (
    <Layout location={props.location}>
      <Seo title="Battle Mapper" />
      <Hero
        title="Battle Mapper"
        content="A small applet (wip) for generating battle maps for scenario writing"
      />
      <Instructions />
      <BattleMap />
    </Layout>
  );
};

export default BattleMapper;
