// Libraries
import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import html2canvas from 'html2canvas';
// Components
import Loader from './loader';
import TerrainMenu from './terrain-menu';
// Assets
import { mapImg, frameImg } from '../utils/terrain';
// Constants
import { KEYS } from '../utils/constants';
// Styles
import * as styles from '../components/battle-map.module.css';
import useIsMobile from '../hooks/useIsMobile';

const FRAME_CONTENT_RATIO_PERCENTAGE_WIDTH = 22.771084337349397;
const FRAME_CONTENT_RATIO_PERCENTAGE_HEIGHT = 23.668639053254437;

const BattleMap = () => {
  const { isMobile } = useIsMobile();
  // REFS
  const battleMapRef = useRef();
  const battleMapContainerRef = useRef();
  const frameRef = useRef();

  // STATE
  const [stage, setStage] = useState(undefined);
  const [isGeneratingScreenShot, setIsGeneratingScreenShot] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setStage(
      new Konva.Stage({
        container: battleMapRef.current,
        width: mapImg.width,
        height: mapImg.height,
      })
    );
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (!stage) return;

    // INITIALIZE
    layer.add(battleMap);
    layer.add(transform);
    layer.add(selectionRectangle);
    layer.draw();

    stage.add(layer);

    initializeEventHandlers();
  }, [stage]);

  if (typeof window === 'undefined') return null;
  // For Controlling c/p
  let copies = [];

  const frameWidth = isMobile ? frameRef.current?.width : frameImg.width;
  const frameHeight = isMobile ? frameRef.current?.height : frameImg.height;

  const layer = new Konva.Layer();
  const battleMap = new Konva.Rect({
    x: 0,
    y: 0,
    width:
      frameWidth - frameWidth * (FRAME_CONTENT_RATIO_PERCENTAGE_WIDTH / 100),
    height:
      frameHeight - frameHeight * (FRAME_CONTENT_RATIO_PERCENTAGE_HEIGHT / 100),
    fillPatternImage: mapImg,
  });
  const transform = new Konva.Transformer();
  const selectionRectangle = new Konva.Rect({
    fill: 'rgba(0,0,0,0.5)',
    visible: false,
  });

  // For controlling position of items
  let x1 = undefined;
  let y1 = undefined;
  let x2 = undefined;
  let y2 = undefined;

  const initializeEventHandlers = () => {
    stage.on('mousedown', (e) => {
      // do nothing if we mousedown on any shape
      if (e.target !== battleMap) return;

      e.evt.preventDefault();
      x1 = stage.getPointerPosition().x;
      y1 = stage.getPointerPosition().y;
      x2 = stage.getPointerPosition().x;
      y2 = stage.getPointerPosition().y;

      selectionRectangle.visible(true);
      selectionRectangle.width(0);
      selectionRectangle.height(0);
    });

    stage.on('mousemove', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) return;

      e.evt.preventDefault();
      x2 = stage.getPointerPosition().x;
      y2 = stage.getPointerPosition().y;

      selectionRectangle.setAttrs({
        x: Math.min(x1, x2),
        y: Math.min(y1, y2),
        width: Math.abs(x2 - x1),
        height: Math.abs(y2 - y1),
      });
    });

    stage.on('mouseup', (e) => {
      // do nothing if we didn't start selection
      if (!selectionRectangle.visible()) return;

      e.evt.preventDefault();
      // update visibility in timeout, so we can check it in click event
      setTimeout(() => {
        selectionRectangle.visible(false);
      });

      const shapes = stage.find('.rect');
      const box = selectionRectangle.getClientRect();
      const selected = shapes.filter((shape) =>
        Konva.Util.haveIntersection(box, shape.getClientRect())
      );
      transform.nodes(selected);
    });

    stage.on('click tap', (e) => {
      // if we are selecting with rect, do nothing
      if (selectionRectangle.visible()) return;

      // if click on empty area - remove all selections
      if (e.target === stage) {
        transform.nodes([]);
        return;
      }

      // do nothing if clicked NOT on our rectangles
      if (!e.target.hasName('rect')) return;

      // do we pressed shift or ctrl?
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
      const isSelected = transform.nodes().indexOf(e.target) >= 0;

      if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        transform.nodes([e.target]);
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = transform.nodes().slice(); // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1);
        transform.nodes(nodes);
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = transform.nodes().concat([e.target]);
        transform.nodes(nodes);
      }
    });
  };

  const handleKeyDown = (e) => {
    // when plus or minus are clicked increment or decrement the z-index of the selection
    if ([KEYS.plus, KEYS.underscore].includes(e.key) && e.shiftKey) {
      transform.nodes().forEach((node) => {
        if (e.key === KEYS.plus) {
          node.moveUp();
        } else {
          node.moveDown();
        }
      });
    }

    if (e.key === KEYS.backspace) {
      transform.nodes().forEach((node) => node.destroy());
      transform.nodes([]);
    }

    // Ctrl + C (Cmd + C)
    if (e.key === KEYS.c && (e.ctrlKey || e.metaKey)) {
      copies = transform.nodes().map((node) => node.clone());
    }

    if (e.key === KEYS.v && (e.ctrlKey || e.metaKey)) {
      copies.forEach((copy) => layer.add(copy));
      // Make sure the copies are the ones that are
      // in the selection
      transform.nodes(copies);
      copies = [];
    }
  };

  const handleSave = (e) => {
    setIsGeneratingScreenShot(true);
    html2canvas(document.getElementById('battle-map-container'))
      .then((canvas) => {
        let a = document.createElement('a');
        a.download = 'new_map.png';
        a.href = canvas.toDataURL('image/png');
        a.click();
        a = null; // garbage collection
      })
      .finally(() => {
        setIsGeneratingScreenShot(false);
      });
  };

  return (
    <div className={styles.battleMapApplet}>
      <div className={styles.battleMapContainer} ref={battleMapContainerRef}>
        <div id="battle-map-container">
          <div
            role={'application'}
            className={styles.battleMap}
            ref={battleMapRef}
            tabIndex="0"
            onKeyDown={handleKeyDown}
          ></div>
          <img ref={frameRef} src={frameImg.src} alt="" />
        </div>
        <Loader
          isLoading={isGeneratingScreenShot}
          text="Generating image file..."
        >
          <div className={styles.saveButtonContainer}>
            <button className={styles.saveButton} onClick={handleSave}>
              Save map as image
            </button>
          </div>
        </Loader>
      </div>
      <div className={styles.terrainMenu}>
        <TerrainMenu layer={layer} />
      </div>
    </div>
  );
};

export default BattleMap;
